import { Box } from '@mui/material';
import { ReactComponent as PayPuntaOrangeLogo } from '../assets/logo-pp-orange.svg';
import ManArmsCrossed from '../assets/man-arms-crossed.png';
import Message from './Message';

function NotFound() {
  return (
    <Message
      id="not-found"
      payPuntaLogo={<PayPuntaOrangeLogo />}
      title="¡Lo sentimos!"
      text="La página que buscas no existe, si deseas salir solo debes cerrar la ventana."
      image={
        <Box className="not-found-image-container">
          <img src={ManArmsCrossed} alt="Hombre cruzando los brazos" />
        </Box>
      }
    />
  );
}

export default NotFound;
