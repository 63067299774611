import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { config } from '../config/config';

interface CustomResponse extends AxiosResponse {
  maintenance: boolean;
}

const instance: AxiosInstance = axios.create({ baseURL: config.backendUrl });

instance.interceptors.response.use((response: AxiosResponse<CustomResponse>) => {
  const isMaintenanceUrl = window.location.href.includes('maintenance');
  const isInMaintenance = response.data.maintenance;

  const targetUrl = isInMaintenance ? '/maintenance' : '/';
  if (isInMaintenance !== isMaintenanceUrl) window.location.replace(targetUrl);

  return response;
});

export default instance;
