/* eslint-disable jsx-a11y/anchor-has-content */
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PayPuntaOrangeLogo } from '../../assets/logo-pp-orange.svg';
import WorkingMan from '../../assets/working-man.png';
import Message from '../../components/Message';
import useVerticals from '../../hooks/queries/useVerticals';

function MaintenanceScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: verticals } = useVerticals();

  useEffect(() => {
    if (verticals?.length === 0) {
      navigate('/');
    }
  }, [navigate, verticals]);

  return (
    <Message
      id="maintenance-page"
      payPuntaLogo={<PayPuntaOrangeLogo />}
      title={t('maintenance-screen.title')}
      text={t('maintenance-screen.text')}
      image={
        <Box className="maintenance-image-container">
          <img src={WorkingMan} alt="Working man" />
        </Box>
      }
    />
  );
}

export default MaintenanceScreen;
