import { Box } from '@mui/system';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CurvedArrow } from '../assets/curved-arrow.svg';
import { RootState } from '../store/config';
import { useAppSelector } from '../store/hooks';
import { PaymentResponse } from '../types/paymentResult.types';
import Loading from './Animations/Loading';
import PDFReceipt from './PDFReceipt';
import RotateButton from './RotateButton/RotateButton';

export interface MessageProps {
  id?: string;
  payPuntaLogo: JSX.Element;
  title: string;
  text: string | JSX.Element;
  downloadIcon?: JSX.Element;
  downloadText?: string;
  retryIcon?: JSX.Element;
  retryText?: string;
  retryUrl?: string;
  isLoading?: boolean;
  isMaintenancePage?: boolean;
  image?: JSX.Element;
}

function Message({
  id,
  payPuntaLogo,
  title,
  text,
  downloadIcon,
  downloadText,
  retryIcon,
  retryText,
  retryUrl,
  isLoading,
  image,
}: MessageProps) {
  const { t } = useTranslation();
  const paymentSuccess: PaymentResponse | null = useAppSelector((state: RootState) => state.payment.payment);

  return (
    <Box id={id} className="message">
      <Box className="content">
        <Box className="text-container">
          {payPuntaLogo}
          <h1>{title}</h1>
          <p className="text">{text}</p>
          <Box className="payment-buttons-container">
            {downloadText && (
              <PDFDownloadLink
                document={<PDFReceipt paymentSuccess={paymentSuccess} />}
                className="button-wrap"
                fileName={paymentSuccess?.data?.paymentId?.replace(/\./g, '-') || 'document'}
              >
                <span className="icon-wrap">{downloadIcon}</span>
                <span className="text-wrap">{downloadText}</span>
              </PDFDownloadLink>
            )}
            {retryUrl && (
              <a href={retryUrl} className="button-wrap">
                <span className="icon-wrap">{retryIcon}</span>
                <span className="text-wrap">{retryText}</span>
              </a>
            )}
          </Box>
        </Box>
        {isLoading ? (
          <Box>
            <Loading height={300} width={350} />
          </Box>
        ) : (
          <Box className="return-site-button-container">
            <RotateButton href="/" text={t('success.back-text')} icon={<CurvedArrow />} rotate size={140} />
          </Box>
        )}
      </Box>
      {image}
    </Box>
  );
}

export default Message;
